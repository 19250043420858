import {
  useRef,
  createContext,
  useContext,
  useCallback,
  useSyncExternalStore,
  // useState,
  // useEffect,
} from "react";
// import _get from "lodash/get";
// import _set from "lodash/set";

// Adopted from: Jack Herrington
// https://youtu.be/ZKlXqrcBx88
// https://github.com/jherr/fast-react-context/blob/main/fast-context-generic/src/createFastContext.tsx

export default function createFastContext(initialState) {
  function useStoreData() {
    const store = useRef(initialState);

    const get = useCallback(() => store.current, []);

    const subscribers = useRef(new Set());

    const set = useCallback((keyOrValueOrCallback, maybeValueOrCallback) => {
      switch (typeof keyOrValueOrCallback) {
        case "object":
          // -- Merge current object with new object
          // store.current = { ...store.current, ...keyOrValueOrCallback };
          Object.assign(store.current, keyOrValueOrCallback);
          break;
        case "function":
          // -- Merge current object with callback return
          // store.current = { ...store.current, ...keyOrValueOrCallback(store.current) };
          Object.assign(store.current, keyOrValueOrCallback(store.current));
          break;
        case "string":
          // -- Overwrite key in current object with value or callback return
          if (typeof maybeValueOrCallback === "function") {
            store.current[keyOrValueOrCallback] = maybeValueOrCallback(
              store.current[keyOrValueOrCallback],
            );
            // _set(
            //   store.current,
            //   keyOrValueOrCallback,
            //   maybeValueOrCallback(_get(store.current, keyOrValueOrCallback)),
            // );
          } else {
            store.current[keyOrValueOrCallback] = maybeValueOrCallback;
            // _set(store.current, keyOrValueOrCallback, maybeValueOrCallback);
          }
          break;
        default:
          return;
      }

      subscribers.current.forEach((callback) => callback());
    }, []);

    const subscribe = useCallback((callback) => {
      subscribers.current.add(callback);
      return () => subscribers.current.delete(callback);
    }, []);

    return {
      get,
      set,
      subscribe,
    };
  }

  const StoreContext = createContext(null);

  function Provider({ children }) {
    return (
      <StoreContext.Provider value={useStoreData()}>
        {children}
      </StoreContext.Provider>
    );
  }

  function useStoreSet() {
    const store = useContext(StoreContext);
    if (!store) {
      throw new Error("Store not found");
    }

    return store.set;
  }

  function useStoreGet() {
    const store = useContext(StoreContext);
    if (!store) {
      throw new Error("Store not found");
    }

    return store.get;
  }

  function useStore(selector) {
    const store = useContext(StoreContext);
    if (!store) {
      throw new Error("Store not found");
    }

    const state = useSyncExternalStore(
      store.subscribe,
      () => selector(store.get()),
      () => selector(initialState),
    );

    return [state, store.set];
  }

  // function useStore2(selectorWrappedInUseCallback) {
  //   const store = useContext(StoreContext)
  //   if (!store) {
  //     throw new Error('Store not found')
  //   }
  //
  //   const [state, setState] = useState(
  //     selectorWrappedInUseCallback(store.get()),
  //   )
  //
  //   useEffect(() => {
  //     return store.subscribe(() =>
  //       setState(selectorWrappedInUseCallback(store.get())),
  //     )
  //   }, [store, selectorWrappedInUseCallback])
  //
  //   return [state, store.set]
  // }

  return {
    Provider,
    useStoreSet,
    useStoreGet,
    useStore,
    // useStore2,
  };
}
