import { useCallback, useEffect, useRef } from "react";
import { router, usePage } from "@inertiajs/react";
import createFastContext from "@/Hooks/createFastContext";

const store_defaults = {
  user: null,
  is_processing: false,
  is_open: false,
  has_status: false,
  use_redirect_to: false,
  redirect_to: "",
  mode: null,
  prefill: null,
};

export const { Provider, useStore, useStoreSet, useStoreGet } =
  createFastContext(store_defaults);

const getStoreSetterCallback =
  (keyObjectOrCallback, maybeValueOrCallback) => (state) => {
    let oldState = { ...state };
    // let keysToSet = null;

    // Update state
    switch (typeof keyObjectOrCallback) {
      case "string":
        // key, valueOrCallback
        // keysToSet = [keyObjectOrCallback];

        if (typeof maybeValueOrCallback === "function") {
          // callback
          state[keyObjectOrCallback] = maybeValueOrCallback(
            oldState[keyObjectOrCallback]
          );
        } else {
          // value
          state[keyObjectOrCallback] = maybeValueOrCallback;
        }
        break;
      case "object":
        if (Array.isArray(keyObjectOrCallback)) {
          return oldState;
        }
        // object to merge
        // state = Object.assign(oldState, keyObjectOrCallback);
        Object.assign(state, keyObjectOrCallback);
        // keysToSet = Object.keys(keyObjectOrCallback);
        break;
      // case "function":
      //   // callback
      //   state = keyObjectOrCallback(oldState);
      //   break;
      default:
        return oldState;
    }

    // Handle actions
    if (state?.open) {
      if (typeof state.open === "string") {
        state.mode = state.open;
      } else {
        // Object.assign(state, state.open);
        Object.entries(state.open).forEach(([k, v]) => {
          state[`${k}`] = v;
        });
      }
      state.is_open = true;
      delete state.open;
    }

    if (state?.close) {
      state.is_open = false;
      state.has_status = false;
      state.use_redirect_to = false;
      state.redirect_to = "";
      delete state.close;
    }

    // if (keysToSet?.length) {
    //   // Save certain items in localStorage.
    //   [
    //     "current_step",
    //     "current_attendee_nr",
    //     "attendees",
    //     "customer",
    //     "checkout",
    //     // "...",
    //   ].forEach((key) => {
    //     if (keysToSet.includes(key)) {
    //       localStorage.setItem(`auth_${key}`, JSON.stringify(state[key]));
    //     }
    //   });
    // }

    return state;
  };

export const useAuthStoreSet = (key = "", transformCallback = null) => {
  const setStore = useStoreSet();

  const storeKey = useRef(key);
  const transform = useRef(transformCallback);

  const setValue = useCallback(
    (keyObjectOrCallback, maybeValueOrCallback) => {
      if (storeKey.current?.length) {
        setStore(
          getStoreSetterCallback(
            storeKey.current,
            typeof transform.current === "function"
              ? transform.current(keyObjectOrCallback)
              : keyObjectOrCallback
          )
        );
      } else {
        setStore(
          getStoreSetterCallback(keyObjectOrCallback, maybeValueOrCallback)
        );
      }
    },
    [setStore]
  );

  return setValue;
};

export function useAuthInPage() {
  const setAuthStore = useAuthStoreSet();
  const [isAuthModalProcessing] = useStore((s) => s.is_processing);
  const [isAuthModalOpen] = useStore((s) => s.is_open);
  const { auth } = usePage().props;

  // Sync user from page
  useEffect(() => {
    setAuthStore("user", auth?.user ?? null);
  }, [setAuthStore, auth]);

  // Ensure auth modal closes on navigation or navigation is cancelled when processing
  useEffect(() => {
    return router.on("before", () => {
      if (isAuthModalOpen) {
        if (isAuthModalProcessing) {
          return false;
        } else {
          const skipClosingAuthModal = localStorage.getItem(
            "skip_close_auth_modal"
          );
          if (!skipClosingAuthModal) {
            setAuthStore("close", true);
          } else {
            localStorage.removeItem("skip_close_auth_modal");
          }
        }
      }
    });
  }, [setAuthStore, isAuthModalProcessing, isAuthModalOpen]);
}

const AuthStore = ({ children }) => {
  return <Provider>{children}</Provider>;
};

export default AuthStore;
