import { useEffect } from "react";
// import axios from "axios"; // Note: instead use global axios from bootstrap here.
import { router } from "@inertiajs/react";
import { useStoreGet as useAuthStoreGet } from "@/Store/AuthStore";

// TODO: smarter timing:
//       - when focus on window, start every minute
//       - when no focus on window, every 5 (?) minutes
// TODO: handling errors:
//       - ignore if connection error
//       - refresh if Laravel error

const Heartbeat = ({ interval = 5 * 60 * 1000 }) => {
  const getApiStore = useAuthStoreGet();

  useEffect(() => {
    let ignore = false;
    let intervalRef = setInterval(() => {
      axios
        .post(route("customer.heartbeat.post"), {
          id: getApiStore()?.user?.id ?? 0,
          v: localStorage.getItem("version") ?? "",
        })
        .then((response) => {
          if (!ignore) {
            // console.log("r:", response);
            let status = (response?.data ?? "").split("|");
            localStorage.setItem("version", status?.[1] ?? "");
            switch (status[0]) {
              // user logged out
              /* eslint-disable no-fallthrough */
              case "o":
                localStorage.setItem("reset_customer", 1);
                localStorage.removeItem("fill_from_user");
              // version mismatch
              case "r":
              // user logged in
              case "i":
              // user switched
              case "x":
                // force-reload
                router.reload();
                break;
              /* eslint-enable no-fallthrough */
              // user in sync
              case "s":
              default:
                break;
            }
          }
        })
        .catch((error) => {
          if (!ignore) {
            if (
              error?.response?.data?.errors || // Laravel validation errors object
              error?.response?.data?.message // Laravel single validation error string
            ) {
              // Assume logout/loss of session
              localStorage.setItem("reset_customer", 1);
              localStorage.removeItem("fill_from_user");

              // force-reload
              router.reload();
            }
            // console.log("hb-error:", [
            //   error?.response?.data?.errors || // Laravel validation errors object
            //     error?.response?.data?.message || // Laravel single validation error string
            //     error?.message || // Single error string (assumed)
            //     error,
            // ]);
          }
        });
    }, interval);

    return () => {
      ignore = true;
      clearInterval(intervalRef);
    };
  }, [interval, getApiStore]);

  return null;
};

export default Heartbeat;
